import { Socket } from 'socket';
import { ReconnectingWebSocket } from 'reconnecting-websocket';
import store from '../../../redux/store/configureStore';
import { acceptChat } from '../../../redux/actions/async/hmf';
import {
	evtCHAT_ERRAND_ADD,
	evtCHAT_NEW_MESSAGE,
	evtDATA_SYNC,
	evtNOTIFICATION_MESSAGE,
	evtGLOBAL_SEARCH,
	evtNOTIFICATION_MESSAGE_DISMISS,
	evtNOTIFICATION_MESSAGE_READ,
	evtNOTIFICATION_MESSAGE_UNREAD,
	evtSYNC_STATE,
	evtCHAT_REGISTER,
	evtAGENT_ACCEPT_CHAT,
} from '../../../redux/constants/constants';
import {
	CHAT_crOwner,
} from '../constants';
import { listenEvents } from '../../../redux/actions/async/call';
import { toggleChatSource, updateChatSourceStatus } from '../../../redux/actions/hmf';
import {
	chatSetSeenMessages,
	playSoundNotif
} from '../../../redux/actions/async/echat';
import {
	detectLanguage
} from '../../../redux/actions/async/errand';
import {
	canTranslateChatSelector,
	getChatTranslateToSelector,
} from '../../../redux/selectors/translation';

const justAck = (msg, ack) => { ack() }

function setupAgentSocketListeners(ws) {
	// These listeners handle replies to back end only (acks). Changes to
	// UI should be done only via AgentSocket.Dispatcher.
	ws.SetEventListener(evtSYNC_STATE, function(cb) {
		let state = store.getState()
		, s = {
			chats: {},
			notification: {
				maxMessageId: state.app.notification.maxMessageId,
			}
		};
		$.each(state.app.workflow.errandListChat, (i, ce) => {
			let seen = [];
			$.each(ce.messages, (j, m) => {
				if (m.seen && (m.fromClient || m.aid != initialChatData.agentID)) {
					seen.push(m.id);
				}
			});
			if (seen.length > 0) {
				s.chats[ce.sessionId] = { seen };
			}
		})
		cb(s);
	});
	ws.SetEventListener(evtCHAT_REGISTER, function(msg, cb) {
		if (msg.chatErrands) {
			msg.chatErrands.map((ce) => {
				$.each(ce.messages, (i, m) => {
					if(!m.seen){
						if(initialChatData.playSound){
							playSoundNotif();
							return false;
						}
					}
				});
			});
		}
		cb('agent registered');
	});
	ws.SetEventListener(evtCHAT_ERRAND_ADD, function(msg, ack) {
		let state = store.getState()
		, canTranslate = canTranslateChatSelector(state);
		$.each(msg.chatErrand.messages, (i, m) => {
			if(!m.seen){
				if(canTranslate){
					let sessionId = msg.sessionId;
					store.dispatch(detectLanguage(m.text, msg.sessionId));
				}
				if(initialChatData.playSound){
					playSoundNotif();
					return false;
				}
			}
		});
		ack();
	});
	ws.SetEventListener(evtCHAT_NEW_MESSAGE, function(msg, ack) {
		let state = store.getState()
		, errandListChat = state.app.workflow.errandListChat
		, nAdded = 0
		, nmessage = 0
		, currentChat
		, ids = []
		;
		errandListChat.map((ce) => {
			if (ce.sessionId != msg.sessionId) {
				return;
			}
			if (state.app.errand.chat && state.app.errand.chat.sessionId == ce.sessionId) {
				currentChat = state.app.errand.chat;
			}
			nmessage = ce.messages.length;
			$.each(msg.messages, (i, m) => {
				if (ce.messageById[m.id]) {
					nAdded++;
					ids.push(m.id);
				}
				if(!m.seen){
					if(initialChatData.playSound){
						playSoundNotif();
					}
				}
			});
		});
		if (nAdded != msg.messages.length) {
			ws.SendEvent("ERROR", {
				error: "ERR_REDUCER",
				message: "nAdded "+nAdded+" != msg.messages.length "+msg.messages.length+" (dispatcher did not add new chat message to store?)"
			});
		}
		ack(nmessage, nAdded);

		if (currentChat && ids.length > 0) {
			chatSetSeenMessages(store.dispatch, currentChat, ids)
		}
	});
}

const agentSocket = () => {
	let ws = Socket('wss://'+document.location.host+"/message.ws");
	ws.WebSocket = ReconnectingWebSocket;
	setupAgentSocketListeners(ws);
	return ws;
};

var AgentWs = agentSocket();
export default AgentWs;
